/* eslint-disable complexity */
import { AnyAction } from 'redux';

import type { EmailLoginResponse } from '@workerbase/api/http/auth';
import { Languages } from '@workerbase/domain/common';
import { LoggedUser } from 'services/types/User';

import { LoginActions } from './actions';

export type LoginState = Readonly<{
  user: LoggedUser | null;
  isAuthenticated: boolean;
  loginError: string | null;
  isLoadingInitialListConfig: boolean;
}>;

export const initialState: LoginState = {
  user: null,
  isAuthenticated: false,
  loginError: null,
  isLoadingInitialListConfig: false,
};

const mapPayloadToUser = (payload: EmailLoginResponse): LoggedUser => ({
  id: payload.user._id,
  firstName: payload.user.firstName,
  lastName: payload.user.lastName,
  language: payload.user.language as Languages,
  disableDeviceLock: payload.user.disableDeviceLock || false,
  isDeveloper: payload.user.isDeveloper || false,
  isRootAdministrator: payload.user.superUser || false,
  email: payload.user.email,
  roleIds: payload.user.roles || [],
  isOnPremise: payload.isOnPremise || false,
  isWorkbenchEnabled: payload.isWorkbenchEnabled || false,
  isMyWorkEnabled: payload.isMyWorkEnabled || false,
  isAiEnabled: payload.isAiEnabled || false,
  speedUpEnabled: payload.speedUpEnabled || false,
  speedUpInitialTaskPrompt: payload.speedUpInitialTaskPrompt || '',
  isGDriveEnabled: payload.isGDriveEnabled || false,
  isHideProfilePictureEnabled: payload.isHideProfilePictureEnabled || false,
  plantId: payload.user.plantId,
});

interface LoginFailureActionPayload {
  errorMessage: string;
}

const reducer = (state: LoginState = initialState, action: AnyAction): LoginState => {
  switch (action.type) {
    case LoginActions.LOGIN_SSO_SUCCESS: {
      const payload = action.payload as EmailLoginResponse;

      return {
        ...state,
        user: mapPayloadToUser(payload),
        isAuthenticated: true,
        loginError: null,
      };
    }
    case LoginActions.LOGIN_USER_SUCCESS: {
      const payload = action.payload as EmailLoginResponse;
      return {
        ...state,
        user: mapPayloadToUser(payload),
        isAuthenticated: true,
        loginError: null,
      };
    }

    case LoginActions.LOGIN_USER_UPDATE_DATA: {
      const updatedUser = {
        isOnPremise: state.user?.isOnPremise,
        isWorkbenchEnabled: state.user?.isWorkbenchEnabled,
        isMyWorkEnabled: state.user?.isMyWorkEnabled,
        isAiEnabled: state.user?.isAiEnabled,
        isGDriveEnabled: state.user?.isGDriveEnabled,
        isHideProfilePictureEnabled: state.user?.isHideProfilePictureEnabled,
        speedUpEnabled: state.user?.speedUpEnabled,
        speedUpInitialTaskPrompt: state.user?.speedUpInitialTaskPrompt || '',
        plantId: state.user?.plantId,
        ...action.payload.user,
      } as LoggedUser;

      return {
        ...state,
        user: updatedUser,
      };
    }
    case LoginActions.LOGIN_USER_FAILURE: {
      const payload = action.payload as LoginFailureActionPayload;
      return {
        ...initialState,
        loginError: payload.errorMessage,
      };
    }
    case LoginActions.SET_IS_LOADING_INITIAL_LIST_CONFIG: {
      const isLoading = action.payload as boolean;

      return { ...state, isLoadingInitialListConfig: isLoading };
    }
    case LoginActions.LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
