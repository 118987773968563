import { useMemo } from 'react';
import { DocumentBuilderStepTypes, IsSectionStep, BuilderStep } from '@workerbase/domain/document';
import { StepListItem } from 'components/DocumentBuilder/types';

export interface QuestionOption {
  label: string;
  value: string;
  questionData: BuilderStep;
}

const DISPLAY_OPTIONS_ALLOWED_STEP_TYPES = [
  DocumentBuilderStepTypes.InputChecklist,
  DocumentBuilderStepTypes.InputNumber,
  DocumentBuilderStepTypes.ContainerSection,
];

const findCurrentStepIndex = (steps: StepListItem[], currentStepId: string): number =>
  steps.findIndex((step) => step.step._id === currentStepId);

const collectNestedQuestions = (sectionStep: StepListItem, sectionIndex: number): [number, number][] => {
  if (!IsSectionStep(sectionStep.step)) {
    return [];
  }
  return sectionStep.step.steps.reduce<[number, number][]>((acc, innerStep, innerIndex) => {
    if (DISPLAY_OPTIONS_ALLOWED_STEP_TYPES.includes(innerStep.step.type)) {
      acc.push([sectionIndex, innerIndex]);
    }
    return acc;
  }, []);
};

const collectAllowedQuestions = (steps: StepListItem[], currentStepIndex: number): [number, number?][] =>
  steps.reduce<[number, number?][]>((acc, step, index) => {
    if (index >= currentStepIndex || !DISPLAY_OPTIONS_ALLOWED_STEP_TYPES.includes(step.step.type)) {
      return acc;
    }

    if (IsSectionStep(step.step)) {
      const nestedQuestions = collectNestedQuestions(step, index);
      nestedQuestions.forEach((q) => acc.push(q));
      return acc;
    }

    acc.push([index]);
    return acc;
  }, []);

const createQuestionOption = (steps: StepListItem[], [index, innerIndex]: [number, number?]): QuestionOption => {
  const step = steps[index].step;
  const questionData =
    innerIndex !== undefined && IsSectionStep(step) ? step.steps[innerIndex].step : (step as BuilderStep);

  const title =
    innerIndex !== undefined
      ? `${step.title || 'Section (no title)'}: ${questionData.title || `Question ${innerIndex + 1} (no title)`}`
      : questionData.title;

  return {
    label: title || `Question ${index + 1}${innerIndex !== undefined ? `- ${innerIndex + 1}` : ''} (has no title)`,
    value: questionData._id,
    questionData,
  };
};

export const getDisplayCriteriaOptions = (steps: StepListItem[], currentStepId: string): QuestionOption[] => {
  if (!steps.length || !currentStepId) {
    return [];
  }

  const currentStepIndex = findCurrentStepIndex(steps, currentStepId);
  const allowedQuestionIndexes = collectAllowedQuestions(steps, currentStepIndex);
  return allowedQuestionIndexes.map((indexes) => createQuestionOption(steps, indexes));
};

export const useDisplayCriteriaOptions = (steps: StepListItem[], currentStepId: string): QuestionOption[] =>
  useMemo(() => getDisplayCriteriaOptions(steps, currentStepId), [steps, currentStepId]);
