export enum MqttTopics {
  APP_TASK = 'workerbase/app/+/task/+',

  CONNECTOR_INIT = 'workerbase/connectors/+/init', // connectorId
  CONNECTOR_SUBSCRIBE = 'workerbase/connectors/+/subscribe', // connectorId
  CONNECTOR_UNSUBSCRIBE = 'workerbase/connectors/+/unsubscribe', // connectorId
  CONNECTOR_RECEIVE = 'workerbase/connectors/+/receive', // connectorId
  CONNECTOR_MESSAGE_RECEIVED = 'workerbase/connectors/+/message', // connectorId
  CONNECTOR_HTTP_INCOMING = 'workerbase/connectors/+/http-incoming', // connectorId
  CONNECTOR_HTTP_INCOMING_PREPARED = 'workerbase/connectors/+/http-incoming-prepared', // connectorId
  CONNECTOR_PUBLISH = 'workerbase/connectors/+/send', // connectorId
  CONNECTOR_SHUTDOWN = 'workerbase/connectors/+/shutdown', // connectorId
  CONNECTOR_STATUS_UPDATE = 'workerbase/connectors/+/status', // connectorId

  DATABASE = 'workerbase/database/+',
  DATABASE_ITEM = 'workerbase/databaseItem/+',

  DEVICE = 'workerbase/device/+', // deviceId
  DEVICE_TMP = 'workerbase/device_tmp/+', // deviceId
  DEVICE_INSTALL = 'workerbase/device/+/install', // deviceId
  DEVICE_INSTALL_UPDATE = 'workerbase/device/+/install/+', // {deviceId}/install/{installId}
  DEVICE_FORCE_CLIENT_REFRESH = 'workerbase/device/+/refresh-client', // deviceId

  DEVICE_STATUS_UPDATE = 'workerbase/hook/device/update/+',

  EMAIL = 'workerbase/email',

  E2E_OFFLINE_TASK = 'workerbase/e2e/offline/+/task', // deviceId

  EVENT = 'workerbase/event/+', // eventId
  EVENT_NOT_FOUND = 'workerbase/event/+/ruleNotFound', // unused?
  EXTERNAL_EVENT = 'workerbase/externalEvent/+', // eventId or connectorId

  FORM_RESULT = 'workerbase/form-result/+', // formResultId

  NOTIFICATION_FORM_RESULT_COUNT = 'workerbase/notifications/+/form-result/count', // userId,

  FUNCTION = 'workerbase/function/+',
  FUNCTION_CALL = 'workerbase/function/+/call',
  FUNCTION_LOGS = 'workerbase/function/+/logs',

  HEALTHCHECK = 'workerbase/healthcheck/+', // hostname

  NOTIFICATION_AVAILABILITY = 'workerbase/notifications/+/availability', // userId
  NOTIFICATION_AVAILABILITY_REQUEST = 'workerbase/notifications/+/availability/request', // userId
  NOTIFICATION_BARCODE = 'workerbase/notifications/+/barcode', // userId
  NOTIFICATION_MESSAGE = 'workerbase/notifications/+/message', // userId
  NOTIFICATION_LOCATION = 'workerbase/notifications/+/location', // userId
  NOTIFICATION_PING = 'workerbase/notifications/+/ping', // userId
  /**
   * @deprecated Use {NOTIFICATION_WB_TASK} instead
   * TODO: remove after all customers upgrade to watch v2
   */
  NOTIFICATION_TASK = 'workerbase/notifications/+/task', // userId
  NOTIFICATION_WB_TASK = 'workerbase/v2/notifications/+/task', // userId
  NOTIFICATION_TASK_COUNT = 'workerbase/notifications/+/task/count', // userId
  NOTIFICATION_DASHBOARD_PROJECT_TASK_COUNT = 'workerbase/notifications/dashboard/projects/+/count', // projectId
  NOTIFICATION_DASHBOARD_CREATED_BY_TASK_COUNT = 'workerbase/notifications/dashboard/createdBy/+/count', // userId
  NOTIFICATION_AVAILABLE_TASKS_COUNT = 'workerbase/notifications/+/available-tasks-count', // userId
  NOTIFICATION_AVAILABLE_FORMS_COUNT = 'workerbase/notifications/+/available-forms-count', // userId
  NOTIFICATION_AVAILABLE_KNOWLEDGE_CAPTURE_COUNT = 'workerbase/notifications/+/available-knowledge-capture-count', // userId
  KNOWLEDGE_CAPTURE_COUNT = 'workerbase/notifications/+/knowledge-capture/count', // userId
  KNOWLEDGE_CAPTURE = 'workerbase/knowledge-capture/+', // knowledgeCaptureId

  NOTIFICATION_HOOK_PUBLISH = 'workerbase/hook/notification/published',
  NOTIFICATION_HOOK_DELIVERED = 'workerbase/hook/notification/delivered',
  NOTIFICATION_HOOK_ACKED = 'workerbase/hook/notification/acked',

  AWARENESS_NOTIFICATION_TASK = 'workerbase/notifications/+/awareness', // userId

  LOCATION = 'workerbase/location/+', // locationId | backend just publish but don't listen on this route

  PROJECT = 'workerbase/project/+', // projectId
  REFRESH = 'workerbase/+/refresh', // userId
  REFRESH_DOCUMENTS = 'workerbase/+/refresh/documents', // userId
  HELP_REQUESTED = 'workerbase/request-help',

  ROLE = 'workerbase/role/+', // roleId

  RULE = 'workerbase/rule/+', // ruleId
  RULE_INVOKE = 'workerbase/rule/+/invoke', // ruleId
  RULE_SCHEDULE = 'workerbase/rule/+/schedule', // ruleId

  TASK = 'workerbase/task/+', // taskId
  TASK_BY_EXTERNAL_ID = 'workerbase/task/externalId/+', // taskExternalId
  TASK_MYTASK = 'workerbase/task/+/mytask', // taskId
  TASK_REASON = 'workerbase/task/+/reason', // taskId
  TASK_COUNTS = 'workerbase/task/+/counts', // taskId
  TASK_ACCEPT = 'workerbase/v2/tasks/+/+/accept', // workerbase/v2/tasks/:taskType/:taskId/accept
  TASK_REJECT = 'workerbase/v2/tasks/+/+/reject', // workerbase/v2/tasks/:taskType/:taskId/reject

  USER = 'workerbase/user/+', // userId
  USER_LOGOUT = 'workerbase/user/+/logout', // userId
  USER_LOGIN = 'workerbase/user/+/login', // userId
  USER_ACTIVE_DEVICE = 'workerbase/user/+/active-device', // userId

  WORKINSTRUCTION = 'workerbase/workinstruction/+', // workinstructionId
  WORKINSTRUCTIONS = 'workerbase/workinstructions', //
  WORKINSTRUCTION_INIT = 'workerbase/workinstructions/+/init', // workinstructionId
  WORKINSTUCTIONS_UPLOAD = 'workerbase/workinstructions/upload',
  WORKINSTUCTIONS_UPLOAD_FILE = 'workerbase/workinstructions/upload/+', // fileId
  WORKINSTUCTIONS_UPLOAD_BASE64 = 'workerbase/workinstructions/upload/+/+/+/base64', // upload/{fileId}/{chunkNo}/{chunkLength}/base64
  WORKINSTUCTIONS_UPLOAD_CHUNKS = 'workerbase/workinstructions/upload/+/+/+', // upload/{fileId}/{chunkNo}/{chunkLength}
}
