import _ from 'lodash';

import {
  ListConfigTabs,
  ListConfigSorting,
  ListConfigFiltering,
  ListConfigPagination,
} from '@workerbase/api/http/user';
import { TabActiveColorTypes } from '@workerbase/domain/user';
import { UpdateListConfigActionPayload } from './actions';

export const updatePagination = (state, payload: ListConfigPagination) => ({
  ...state,
  listConfigs: {
    ...state.listConfigs,
    pagination: {
      ...state.listConfigs.pagination,
      itemsPerPage: payload.itemsPerPage || state.listConfigs.pagination.itemsPerPage,
      currentPage: payload.currentPage || state.listConfigs.pagination.currentPage,
    },
  },
});

export const updateSorting = (state, payload: ListConfigSorting) => ({
  ...state,
  listConfigs: {
    ...state.listConfigs,
    sorting: {
      selector: payload.selector,
      sortDirection: payload.sortDirection,
    },
  },
});

export const updateFiltering = (state, payload: ListConfigFiltering) => ({
  ...state,
  listConfigs: {
    ...state.listConfigs,
    filtering: {
      ...state.listConfigs.filtering,
      searchTerms: payload.searchTerms,
    },
  },
});

export const updateSelectedTabKey = (state, payload: ListConfigTabs) => ({
  ...state,
  listConfigs: {
    ...state.listConfigs,
    tabs: {
      ...state.listConfigs.tabs,
      selectedTabKey: payload.selectedTabKey,
    },
  },
});

export const updateListConfig = (state, payload: UpdateListConfigActionPayload) => ({
  ...state,
  listConfigs: {
    ...state.listConfigs,
    sorting: payload.sorting || state.listConfigs.sorting,
    properties: _.unionBy(payload.properties || [], state.listConfigs.properties, 'selector'),
  },
});
// tabs reducer

export const updateStateTabs = (
  initialStateTabs?: ListConfigTabs,
  currentStateTabs?: ListConfigTabs,
  selectedTabKey?: string,
  categoryKey?: string,
  stats?: {
    [key: string]: string | number;
    count: number;
  }[],
): ListConfigTabs | undefined => {
  console.log('updateStateTabs', { initialStateTabs, currentStateTabs, selectedTabKey, categoryKey, stats });
  if (!currentStateTabs || !initialStateTabs) {
    return undefined;
  }
  if (selectedTabKey == null || categoryKey == null || !stats) {
    return currentStateTabs;
  }

  const updatedTabs: ListConfigTabs = {
    selectedTabKey: selectedTabKey.toString(),
    categoryKey,
    categories: currentStateTabs.categories,
    options: [],
  };

  // This object makes it easier to get the count given a category key
  const metaStatsToKeyValue = stats.reduce((acc, stat) => ({ ...acc, [stat[categoryKey]]: stat.count }), {});

  // If the category key is the one by default, it means we need to manually
  // add the "tabs" with count = 0.
  // Inded, the backend won't send the stats for active: false if
  // its sum is null.
  // For any other key, we will just display the list of tabs returned by the backend
  if (categoryKey === initialStateTabs.categoryKey) {
    const updatedOptions = currentStateTabs.options.map((option) => ({
      ...option,
      count: metaStatsToKeyValue[option.key] || 0,
    }));
    return {
      ...updatedTabs,
      options: updatedOptions,
    };
  }

  const updatedOptions = stats.map((stat) => ({
    key: stat[categoryKey] as string,
    title: stat[categoryKey] as string,
    count: stat.count,
    activeColor: TabActiveColorTypes.DEFAULT,
  }));
  return {
    ...updatedTabs,
    options: updatedOptions,
  };
};
