import { BuilderSection, BuilderStep, DocumentBuilder, isBuilderSection } from '@workerbase/domain/document';

/**
 * Generator function that returns the list of all nested steps inside DocumentBuilder
 */
function* filterStepsGenerator(steps: (BuilderStep | BuilderSection)[]): Generator<BuilderStep> {
  for (const stepOrSection of steps) {
    if (isBuilderSection(stepOrSection)) {
      const section = stepOrSection;
      yield* filterStepsGenerator(section.steps);
    } else {
      const step = stepOrSection;
      yield step;
    }
  }
}

export const getAllDocumentBuilderSteps = (documentBuilder: DocumentBuilder): BuilderStep[] =>
  Array.from(filterStepsGenerator(documentBuilder.steps));
