/* eslint-disable complexity */
import { AnyAction } from 'redux';

import { ListConfigPagination } from '@workerbase/api/http/user';
import { ShiftPlansListConfig, ShiftPlanResponse } from '@workerbase/api/http/settings';

import { ShiftPlansActions } from './actions';
import { updatePagination } from '../common/ListConfig/reducers';

export type ShiftPlansState = Readonly<{
  shiftPlansById: { [key: string]: ShiftPlanResponse };
  listConfigs: ShiftPlansListConfig;
  currentListItemsIds: string[];
  errorMessage: string | null;
  isLoading: boolean;
}>;

export const initialState: ShiftPlansState = {
  shiftPlansById: {},
  listConfigs: {
    pagination: { currentPage: 1, itemsPerPage: 20 },
  },
  currentListItemsIds: [],
  errorMessage: null,
  isLoading: false,
};

const reducer = (state: ShiftPlansState = initialState, action: AnyAction): ShiftPlansState => {
  switch (action.type) {
    case ShiftPlansActions.GET_ALL_SHIFT_PLANS_SUCCESS: {
      const payload = action.payload;

      const shiftPlansById = payload.shiftPlans.reduce((prev, shiftPlan) => {
        const updatedShiftPlansById = prev;
        updatedShiftPlansById[shiftPlan._id] = shiftPlan;
        return prev;
      }, {});

      return {
        ...state,
        shiftPlansById: {
          ...state.shiftPlansById,
          ...shiftPlansById,
        },
      };
    }
    case ShiftPlansActions.GET_SHIFT_PLANS_SUCCESS: {
      const payload = action.payload;
      const shiftPlansById = payload.shiftPlans.reduce((prev, shiftPlans) => {
        const updatedShiftPlansById = prev;
        updatedShiftPlansById[shiftPlans._id] = shiftPlans;
        return updatedShiftPlansById;
      }, {});
      return {
        ...state,
        shiftPlansById: {
          ...state.shiftPlansById,
          ...shiftPlansById,
        },
        listConfigs: {
          ...state.listConfigs,
          pagination: {
            ...state.listConfigs.pagination,
            currentPage: payload.meta.page,
            itemsPerPage: payload.meta.perpage,
            totalItems: payload.meta.totalItems,
          },
        },
        currentListItemsIds: payload.shiftPlans.map((shiftPlans) => shiftPlans._id),
      };
    }
    case ShiftPlansActions.GET_SHIFT_PLANS_FAILURE:
    case ShiftPlansActions.GET_ALL_SHIFT_PLANS_FAILURE: {
      const payload = action.payload;
      return {
        ...initialState,
        errorMessage: payload.errorMessage,
        isLoading: false,
        currentListItemsIds: [],
      };
    }
    case ShiftPlansActions.DELETE_SHIFT_PLAN_BY_ID_SUCCESS: {
      const shiftPlanId: string = action.payload.shiftPlanId;

      const shiftPlansById = { ...state.shiftPlansById };
      if (shiftPlansById[shiftPlanId]) {
        delete shiftPlansById[shiftPlanId];
      }

      return {
        ...state,
        shiftPlansById,
        currentListItemsIds: state.currentListItemsIds.filter((itemId) => itemId !== shiftPlanId),
      };
    }
    case ShiftPlansActions.UPDATE_PAGINATION: {
      const payload = action.payload as ListConfigPagination;
      return updatePagination(state, payload);
    }
    default:
      return state;
  }
};

export default reducer;
