import { DocumentVersionResponse, DocumentVersionUpdateBody } from '@workerbase/api/http/document';
import {
  BuilderSection,
  BuilderStep,
  DocumentBuilder,
  DocumentBuilderStepTypes,
  IBuilderStepInfoMedia,
  IBuilderStepInfoText,
  IBuilderStepInputChecklist,
  IBuilderStepInputMedia,
  IBuilderStepInputNumber,
  IBuilderStepInputScancode,
  IBuilderStepInputText,
} from '@workerbase/domain/document';
import { BuilderStepBaseProps } from 'components/DocumentBuilderStep/BuilderStepContainer';
import { ReactNode } from 'react';

// Step List Types
export interface StepListItemMeta {
  error: boolean;
  referencedInSteps?: string[]; // step.id[]
  parentStepId?: string;
}

export type BuilderSectionUIWithStepListItem = Omit<BuilderSection, 'steps'> & {
  steps: StepListItem<BuilderStep>[];
};

export interface StepListItem<T = BuilderStep | BuilderSectionUIWithStepListItem> {
  step: T;
  meta: StepListItemMeta;
}

// Component Props Types
export type StepProps = {
  value: StepListItem;
  isSelected: boolean;
} & Pick<BuilderStepBaseProps, 'isPreviewMode'>;

export type DocumentBuilderStepComponentType = {
  [key in Exclude<DocumentBuilderStepTypes, DocumentBuilderStepTypes.ContainerSection>]: (
    props: StepProps,
    onChange: (newValue: StepListItem<BuilderStep>) => void,
    onDeleteMedia: (mediaId: string) => void,
  ) => ReactNode;
};

// Type Guards
export const isChecklistStep = (step: BuilderStep): step is BuilderStepInputChecklistUI =>
  step.type === DocumentBuilderStepTypes.InputChecklist;

// Specific Builder Step UI Types
export type BuilderStepInfoTextUI = IBuilderStepInfoText & { _id: string };
export type BuilderStepInfoMediaUI = IBuilderStepInfoMedia & { _id: string };
export type BuilderStepInputTextUI = IBuilderStepInputText & { _id: string };
export type BuilderStepInputMediaUI = IBuilderStepInputMedia & { _id: string };
export type BuilderStepInputNumberUI = IBuilderStepInputNumber & { _id: string };
export type BuilderStepInputChecklistUI = IBuilderStepInputChecklist & { _id: string };
export type BuilderStepInputScancodeUI = IBuilderStepInputScancode & { _id: string };

// Document Version Types
export type DocumentVersionUpdateUI = Omit<DocumentVersionUpdateBody, 'steps'> & {
  steps?: StepListItem[];
};

export type DocumentVersionUI = Omit<DocumentVersionResponse, 'documentBuilder'> & {
  documentBuilder?: Omit<DocumentBuilder, 'steps'> & {
    steps: StepListItem[];
  };
};
