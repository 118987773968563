import { ActionMedia } from '../common/ActionMedia.interface';
import { IInputBarcodeRegex } from '../input';
import { DocumentBuilderStepTypes, InputStepTypes } from './DocumentBuilderStepTypes.enum';
import { DocumentSuperTypes } from './DocumentSuperTypes.enum';
import { ISafetySymbols } from './FormDocumentBuilder.interface';
import { FlaggingCriteria, IFlaggable, InputChecklistOperators, InputNumberOperators } from './flaggingCriteria';
import { DisplayCriteriaOperators } from './displayCriteria';
import { IFilterGroup } from '../common';

export interface IBuilderStepBase {
  /** Unique key of the step used only by the FE, used for selection and nested selection.
   *  It's removed before sending the steps to the BE */
  _id?: string;
  variableName: string;
  isCustomVariableName: boolean;
  title: string;
  description?: string;
  type: DocumentBuilderStepTypes;
}

export interface IBuilderStepInput extends IBuilderStepBase {
  type: InputStepTypes;
  required: boolean;
}

export interface IBuilderStepInfoText extends IBuilderStepBase {
  type: DocumentBuilderStepTypes.InfoText;
}

export interface IBuilderStepInfoMedia extends IBuilderStepBase {
  type: DocumentBuilderStepTypes.InfoMedia;
  media: ActionMedia;
}

export interface IBuilderStepInputCheckListOption {
  _id?: string;
  label: string;
  value: string;
}
export interface IBuilderStepInputChecklist extends IBuilderStepInput, IFlaggable {
  type: DocumentBuilderStepTypes.InputChecklist;
  options: IBuilderStepInputCheckListOption[];
  minSelect: number | null;
  multiple: boolean;
  flaggingCriteria: FlaggingCriteria<InputChecklistOperators> | null;
}

export interface IBuilderStepInputText extends IBuilderStepInput {
  type: DocumentBuilderStepTypes.InputText;
  maxCharacters: number | null;
}

export enum BuilderStepInputMediaAccepts {
  Audio = 'audio',
  Video = 'video',
  Image = 'image',
}

export interface IBuilderStepInputMedia extends IBuilderStepInput {
  type: DocumentBuilderStepTypes.InputMedia;
  accept: BuilderStepInputMediaAccepts[];
  multiple: boolean;
  max: number | null;
}

export interface IBuilderStepInputNumber extends IBuilderStepInput, IFlaggable {
  type: DocumentBuilderStepTypes.InputNumber;
  integerDigits: number;
  decimalDigits: number;
  defaultValue: number | null;
  flaggingCriteria: FlaggingCriteria<InputNumberOperators> | null;
}

export interface IBuilderStepInputScancode extends IBuilderStepInput {
  type: DocumentBuilderStepTypes.InputScancode;
  conditions: IInputBarcodeRegex[];
  allowManualInput: boolean;
}

export interface IBuilderSection extends IBuilderStepBase {
  type: DocumentBuilderStepTypes.ContainerSection;
  steps: IBuilderStep[];
  displayCriteria: IFilterGroup<DisplayCriteriaOperators> | null;
  roles: string[] | null;
}

export type BuilderSection = Omit<IBuilderSection, 'steps'> & { _id: string; steps: BuilderStep[] };

export type IBuilderStep =
  | IBuilderStepInfoText
  | IBuilderStepInfoMedia
  | IBuilderStepInputChecklist
  | IBuilderStepInputText
  | IBuilderStepInputMedia
  | IBuilderStepInputNumber
  | IBuilderStepInputScancode;

export type BuilderStep = IBuilderStep & { _id: string };

export interface IDocumentBuilder {
  superType: DocumentSuperTypes;
  steps: (IBuilderStep | IBuilderSection)[];
  createdBy?: string;
  createdAt?: Date;
  requireSignature?: boolean;
  safetySymbols?: ISafetySymbols;
}

export type DocumentBuilder = IDocumentBuilder & { _id: string; steps: (BuilderStep | BuilderSection)[] };
export { InputStepTypes };
