import {
  GetWorkinstructionsQueryParams,
  WorkinstructionGET,
  WorkinstructionPOST,
  WorkinstructionPUT,
} from '@workerbase/types/api/workinstructions';
import { ListConfigSorting } from '@workerbase/api/http/user';
import { ApiResponse, ApiResponsePaginated } from '@workerbase/types/Response';
import { SelectableVariable } from '@workerbase/types/Variable';
import { Workinstruction } from 'services/types/Workinstruction';

import { normalizeWorkinstruction, normalizeWorkinstructionForAPI } from '../normalizers/workinstruction';
import { api } from './api';

export const WORKINSTRUCTIONS_ENDPOINT = '/api/v1/workinstructions';

type GetWorkinstructions = (options: {
  projectId: string;
  page?: number;
  perPage?: number;
  sorting?: ListConfigSorting;
  filtering?: string;
  aggregateLinks?: boolean;
}) => Promise<ApiResponsePaginated<Workinstruction[]>>;

export const getWorkinstructions: GetWorkinstructions = async ({
  projectId,
  page = 1,
  perPage = 20,
  sorting,
  filtering,
  aggregateLinks,
}) => {
  const params: GetWorkinstructionsQueryParams = {
    project: projectId,
    page,
    perpage: perPage,
    sort: sorting?.selector,
    order: sorting?.sortDirection,
    textSearch: filtering,
    aggregateLinks,
  };

  const {
    data: { data: workinstructions, ...rest },
  } = await api.get<ApiResponsePaginated<WorkinstructionGET[]>>(WORKINSTRUCTIONS_ENDPOINT, { params });

  return { data: workinstructions.map((w) => normalizeWorkinstruction(w)), ...rest };
};

type GetWorkinstructionById = (workinstructionId: string) => Promise<Workinstruction>;
export const getWorkinstructionById: GetWorkinstructionById = async (workinstructionId) => {
  const {
    data: { data: workinstruction },
  } = await api.get<{ data: WorkinstructionGET }>(`${WORKINSTRUCTIONS_ENDPOINT}/${workinstructionId}`);

  return normalizeWorkinstruction(workinstruction);
};

export const createWorkinstruction = async (workinstruction: WorkinstructionPOST): Promise<Workinstruction> => {
  const {
    data: { data },
  } = await api.post<{ data: WorkinstructionGET }>(WORKINSTRUCTIONS_ENDPOINT, workinstruction);

  return normalizeWorkinstruction(data);
};

export const transformWorkInstruction = async (
  url: string,
  workinstruction?: WorkinstructionPOST,
): Promise<WorkinstructionPOST | undefined> => {
  const { data } = await api.post<ApiResponse<{ workinstruction?: WorkinstructionPOST }>>(`/${url}`, workinstruction);

  return data.data.workinstruction;
};

export const updateWorkinstructionById = async (
  workinstructionId: string,
  partialWorkinstruction: WorkinstructionPUT,
): Promise<Workinstruction> => {
  const {
    data: { data },
  } = await api.put<{ data: WorkinstructionGET }>(
    `${WORKINSTRUCTIONS_ENDPOINT}/${workinstructionId}`,
    normalizeWorkinstructionForAPI(partialWorkinstruction),
  );

  return normalizeWorkinstruction(data);
};

export const deleteWorkinstructionById = async (workinstructionId: string): Promise<boolean> => {
  await api.delete<{ success: boolean }>(`${WORKINSTRUCTIONS_ENDPOINT}/${workinstructionId}`);

  return true;
};

interface IconId {
  _id: string;
  filename: string;
}

export const getButtonIconIds = async (): Promise<IconId[]> => {
  const {
    data: { data: iconIds },
  } = await api.get<{ data: IconId[] }>(`${WORKINSTRUCTIONS_ENDPOINT}/buttonIcons`);

  return iconIds;
};

export const getVariablesForWI = async (workinstructionId: string): Promise<SelectableVariable[]> => {
  const {
    data: { data },
  } = await api.get(`${WORKINSTRUCTIONS_ENDPOINT}/${workinstructionId}/variables`);
  return data.filter((variable) => variable != null);
};
