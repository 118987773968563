import { RuleDatabaseItemFieldActions } from '../../rule';

export interface ItemPayload {
  [key: string]: DatabaseItemPayloadValue;
}

export interface DatabaseItemUpsert {
  _id?: string;
  externalId?: string;
  plantId: string | null;
  payload: ItemPayload;
  createdAt?: string;
  updatedAt?: string;
}

export interface DatabaseItem {
  _id: string;
  externalId: string;
  database: string;
  deleted: boolean;
  meta: {
    createdAt: string;
    updatedAt: string;
  };
  payload: ItemPayload;
  plantId: string;
}

export type DatabaseItemValue = string | number | boolean | null;

export type DatabaseItemPayloadValue = DatabaseItemValue | Record<string, unknown> | string[];

export interface ActionValue {
  prop: string;
  value: string;
}

export type DatabaseItemsActions = {
  [RuleDatabaseItemFieldActions.REPLACE]: ActionValue[];
  [RuleDatabaseItemFieldActions.INCREASE]: ActionValue[];
  [RuleDatabaseItemFieldActions.DECREASE]: ActionValue[];
  [RuleDatabaseItemFieldActions.REMOVE]: string[];
};

export interface IDatabaseItemChangedBy {
  connectorId?: string;
  databaseId?: string;
  databaseItemId?: string;
  functionId?: string;
  projectId?: string;
  requestMsTimestamp?: number;
  ruleId?: string;
  taskExternalId?: string;
  taskId?: string;
  tokenId?: string;
  userId?: string;
}

enum DatabaseItemHistoryAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface IDatabaseItemHistory {
  action: DatabaseItemHistoryAction;
  databaseId: string;
  databaseItemId: string;
  diff?: object;
  data?: object;
  changedBy: IDatabaseItemChangedBy;
}
