import { ResourceTypes } from '../../common';
import { ConditionOperator } from '../RuleConditionOperator';
import { ConditionValueTypes } from './ConditionValuesTypes';

export interface AvailableConditionBase<ConditionOperators extends ConditionOperator = ConditionOperator> {
  id: string;
  name: string;
  type: ConditionValueTypes;
  value: string; // payload path or prop which corresponds to current condition
  operators: ConditionOperators[];
  disabled: boolean;
  hidden: boolean;
  alternativeValue?: string;
}

export interface AvailableConditionDefault<ConditionOperators extends ConditionOperator = ConditionOperator>
  extends AvailableConditionBase<ConditionOperators> {
  type:
    | ConditionValueTypes.STRING
    | ConditionValueTypes.NUMBER
    | ConditionValueTypes.BOOLEAN
    | ConditionValueTypes.OBJECT_ID;
}
export interface AvailableConditionEnumString<ConditionOperators extends ConditionOperator = ConditionOperator>
  extends AvailableConditionBase<ConditionOperators> {
  type: ConditionValueTypes.ENUM_STRING;
  enum: string[];
}
export interface AvailableConditionEnumNumber<ConditionOperators extends ConditionOperator = ConditionOperator>
  extends AvailableConditionBase<ConditionOperators> {
  type: ConditionValueTypes.ENUM_NUMBER;
  enum: number[];
}

export interface AvailableConditionEnumOption<ConditionOperators extends ConditionOperator = ConditionOperator>
  extends AvailableConditionBase<ConditionOperators> {
  type: ConditionValueTypes.ENUM_OPTION;
  enum: string[];
}

export interface AvailableConditionEnumObject<ConditionOperators extends ConditionOperator = ConditionOperator>
  extends AvailableConditionBase<ConditionOperators> {
  type: ConditionValueTypes.ENUM_OBJECT;
  enum: object[];
}

export interface AvailableConditionResource<ConditionOperators extends ConditionOperator = ConditionOperator>
  extends AvailableConditionBase<ConditionOperators> {
  type: ConditionValueTypes.RESOURCE;
  resource: ResourceTypes;
  url: string;
}

export type AvailableConditionEnum<ConditionOperators extends ConditionOperator = ConditionOperator> =
  | AvailableConditionEnumString<ConditionOperators>
  | AvailableConditionEnumNumber<ConditionOperators>
  | AvailableConditionEnumOption<ConditionOperators>;

export type AvailableCondition<ConditionOperators extends ConditionOperator = ConditionOperator> =
  | AvailableConditionDefault<ConditionOperators>
  | AvailableConditionEnum<ConditionOperators>
  | AvailableConditionResource<ConditionOperators>;

export const AVAILABLE_CONDITIONS_FILTERING_CONDITION_NAMES = [
  'data.payload.workinstructions.originalWorkinstructionsId',
  'data.database',
  'data.form._id',
  'data.connectedTo.$.resourceType',
];
