import { AnyAction } from 'redux';

import { ListConfigPagination } from '@workerbase/api/http/user';
import { TranslationsListConfig, TranslationWithUsagesByProject } from '@workerbase/api/http/settings';

import { updatePagination } from '../common/ListConfig/reducers';
import { TranslationsActions } from './actions';

export type TranslationsState = Readonly<{
  translationsById: { [key: string]: TranslationWithUsagesByProject };
  listConfigs: TranslationsListConfig;
  currentListItemsIds: string[];
}>;

export const initialState: TranslationsState = {
  translationsById: {},
  listConfigs: {
    pagination: { currentPage: 1, itemsPerPage: 20 },
  },
  currentListItemsIds: [],
};

const reducer = (state: TranslationsState = initialState, action: AnyAction): TranslationsState => {
  switch (action.type) {
    case TranslationsActions.GET_TRANSLATIONS_PAGINATED_SUCCESS: {
      const payload = action.payload;
      const translationsById = payload.translations.reduce((prev, translation) => {
        const updatedTranslationsById = prev;
        updatedTranslationsById[translation._id] = translation;
        return updatedTranslationsById;
      }, {});
      return {
        ...state,
        translationsById: {
          ...state.translationsById,
          ...translationsById,
        },
        listConfigs: {
          ...state.listConfigs,
          pagination: {
            ...state.listConfigs.pagination,
            currentPage: payload.meta.page,
            itemsPerPage: payload.meta.perpage,
            totalItems: payload.meta.totalItems,
          },
        },
        currentListItemsIds: payload.translations.map((translation) => translation._id),
      };
    }
    case TranslationsActions.UPDATE_PAGINATION: {
      const payload = action.payload as ListConfigPagination;
      return updatePagination(state, payload);
    }
    default:
      return state;
  }
};
export default reducer;
