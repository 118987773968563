/* eslint-disable no-case-declarations */
/* eslint-disable complexity */
import { ValueTypes } from '@workerbase/domain/common';
import {
  INotifyLocationValueLocation,
  NotificationTypes,
  NotifyLocation,
  NotifyLocationValueTypes,
} from '@workerbase/domain/notification';
import {
  ActionRecipient,
  RuleActionTypes,
  EmailRecipient,
  IActionRolesRecipient,
  IActionSelfInstructionRecipient,
  IActionUserRecipient,
  IActionVariableRecipient,
  IEmail,
  IFunctionAction,
  IRuleActionTask,
  RecipientTypes,
  TriggerTypes,
  SelectedCondition,
} from '@workerbase/domain/rule';
import {
  AutoAcceptTaskAnnouncement,
  AwarenessTaskAnnouncement,
  NoneTaskAnnouncement,
  NotificationTaskAnnouncement,
  TaskAnnouncement,
  TaskAnnouncementType,
} from '@workerbase/domain/task';
import { splitVariables } from '@workerbase/utils/splitVariables';

import {
  AllLocationSetting,
  DefinedLocationSetting,
  EventOriginLocationSetting,
  GlobalLocationSettings,
  LocationSetting,
  VariableLocationSetting,
} from 'services/types/Location';
import { Rule, RuleAction, RuleGET, RulePOST, RulePOSTBase, RuleTrigger } from 'services/types/Rule';
import {
  RuleDatabaseAction,
  RuleDatabasePOST,
  RuleDeviceLocationChangeAction,
  RuleEmailAction,
  RuleEmailPOST,
  RuleEventAction,
  RuleEventPOST,
  RuleFormAction,
  RuleFormPOST,
  RuleKnowledgeCaptureAction,
  RuleKnowledgeCapturePOST,
  RuleFunctionAction,
  RuleFunctionPOST,
  RuleMessageAction,
  RuleMessagePOST,
  RuleMqttPublishAction,
  RuleMqttSubscribeAction,
  RuleRolesAction,
  RuleSkillsAction,
  RuleTaskAction,
  RuleTaskPOST,
  RuleTaskStatusChangeAction,
  RuleTaskStatusChangePOST,
  RuleUpdateTaskVariablesAction,
  RuleWebhookAction,
  RuleWebhookPOST,
  RuleWorkinstructionAction,
  RuleWorkinstructionPOST,
} from 'services/types/Rule/Actions';
import { RuleActionTask } from 'services/types/Rule/Actions/RuleActionTask';
import { FERecipientTypes, Recipient, RecipientWithoutCaller } from 'services/types/Rule/Recipient';
import { Trigger } from 'services/types/Rule/Trigger';
import { TaskStatus } from 'services/types/Task';
import { RuleWorkbenchAction, RuleWorkbenchPOST } from 'services/types/Rule/Actions/RuleWorkbenchAction';

function generateRuleTriggerFromRuleGET(trigger: RuleGET['trigger'], event?: string): RuleTrigger {
  if (trigger.type === TriggerTypes.EXTERNAL_EVENT) {
    return {
      source: trigger.source,
      resourceId: trigger.resourceId || event || '',
    };
  }
  return {
    source: trigger.source,
    schedule: trigger.schedule,
  };
}

function generateEmailFromRuleGet(rule: RuleGET): IEmail {
  const action: RuleEmailAction = rule.action as RuleEmailAction;
  const { email } = action;
  // const email = action.email;

  return {
    from: email?.from || '',
    subject: email?.subject || '',
    text: email?.text || '',
    attachVariables: email?.attachVariables || false,
  };
}

// TODO: Delete this after full rules Migration
// TODO: create a new Format with the Recipient v2 WB-5772
/*
 * for now this is only used when assignment is form and email
 */
function generateRecipientFromBody(recipient: ActionRecipient): Recipient {
  if (recipient.type === RecipientTypes.Selfinstruction) {
    return {
      type: FERecipientTypes.CALLER,
      selfinstruction: true,
    };
  }

  if (recipient.type === RecipientTypes.Variable) {
    return {
      type: FERecipientTypes.VARIABLES,
      variables: recipient.variables.map((variable) => `$${variable}`).join(' ') || '',
    };
  }
  if (recipient.type === RecipientTypes.User) {
    return {
      type: FERecipientTypes.USER,
      isAssignedUserVar: false,
      assignedUser: recipient.user || '',
    };
  }

  return {
    type: FERecipientTypes.ROLES,
    roles: recipient.roles || [],
  };
}

const generateGlobalSettingsFromNotifyLocation = (
  notifyLocation?: NotifyLocation,
): GlobalLocationSettings | undefined => {
  if (!notifyLocation) {
    return undefined;
  }

  if (notifyLocation.type === ValueTypes.Variable) {
    return {
      isVariable: true,
      locationIdVariable: notifyLocation.variable,
    };
  }

  return {
    isVariable: false,
    locationSettings: notifyLocation.location.map(generateLocationSettings),
  };
};

const generateLocationSettings = (location: INotifyLocationValueLocation): LocationSetting => {
  if (location.type === NotifyLocationValueTypes.All) {
    const locationSettings: AllLocationSetting = {
      type: NotifyLocationValueTypes.All,
      levelId: location.level || '',
    };
    return locationSettings;
  }
  if (location.type === NotifyLocationValueTypes.EventOrigin) {
    const locationSettings: EventOriginLocationSetting = {
      type: NotifyLocationValueTypes.EventOrigin,
      levelId: location.level || '',
    };
    return locationSettings;
  }
  if (location.type === NotifyLocationValueTypes.DefinedLocation) {
    const locationSettings: DefinedLocationSetting = {
      type: location.type,
      partId: location.partId || '',
      levelId: location.level || '',
    };
    return locationSettings;
  }

  const locationSettings: VariableLocationSetting = {
    type: NotifyLocationValueTypes.Variable,
    variable: location.variable || '',
    levelId: location.level || '',
  };
  return locationSettings;
};

// eslint-disable-next-line complexity
function generateRuleActionFromRuleGET(rule: RuleGET): RuleAction | undefined {
  if (rule.noAction === true) {
    return undefined;
  }

  switch (rule.action.type) {
    case RuleActionTypes.WORKINSTRUCTION:
      const wiAction = (rule as unknown as RuleWorkinstructionPOST).action;
      return {
        ...(rule.action as RuleWorkinstructionAction),
        type: RuleActionTypes.WORKINSTRUCTION,
        task: normalizeActionWITask(wiAction?.task),
        recipient: generateRecipientFromBody(wiAction?.recipient),
        globalLocationSettings: generateGlobalSettingsFromNotifyLocation(wiAction?.notifyLocation),
        taskSettings: wiAction?.taskSettings,
      } as RuleWorkinstructionAction;
    case RuleActionTypes.TASK:
    case RuleActionTypes.DELEGATE:
      const taskAction = (rule as unknown as RuleTaskPOST).action;
      return {
        type: rule.action.type,
        skills: rule.action.skills || rule?.assignment?.skills,
        task: normalizeActionWITask(taskAction?.task),
        recipient: generateRecipientFromBody(taskAction?.recipient),
        globalLocationSettings: generateGlobalSettingsFromNotifyLocation(taskAction.notifyLocation),
        taskSettings: taskAction?.taskSettings,
      };
    case RuleActionTypes.FORM:
      return {
        ...(rule.action as RuleFormAction),
        type: RuleActionTypes.FORM,
        recipient: generateRecipientFromBody((rule as unknown as RuleFormPOST).action?.recipient),
        task: {
          ...(rule.action as RuleFormAction).task,
          announcement: normalizeTaskAnnouncementFormValues((rule.action as RuleFormAction)?.task?.announcement),
        },
      };
    case RuleActionTypes.KNOWLEDGE_CAPTURE:
      return {
        ...(rule.action as RuleKnowledgeCaptureAction),
        type: RuleActionTypes.KNOWLEDGE_CAPTURE,
        recipient: generateRecipientFromBody((rule as unknown as RuleKnowledgeCapturePOST).action?.recipient),
        documentType: (rule as unknown as RuleKnowledgeCapturePOST).action?.documentType,
        task: {
          ...(rule.action as RuleKnowledgeCaptureAction).task,
          announcement: normalizeTaskAnnouncementFormValues(
            (rule.action as RuleKnowledgeCaptureAction)?.task?.announcement,
          ),
        },
      };
    case RuleActionTypes.MESSAGE: {
      const ruleMessageAction = (rule as unknown as RuleMessagePOST).action;
      return {
        ...ruleMessageAction,
        type: RuleActionTypes.MESSAGE,
        timeout: ruleMessageAction.timeout ? { seconds: ruleMessageAction.timeout.seconds } : null,
        recipient: generateRecipientFromBody(ruleMessageAction?.recipient),
        globalLocationSettings: generateGlobalSettingsFromNotifyLocation(ruleMessageAction?.notifyLocation),
      };
    }
    case RuleActionTypes.EMAIL:
      return {
        type: RuleActionTypes.EMAIL,
        email: generateEmailFromRuleGet(rule),
        recipient: generateRecipientFromBody(
          (rule as unknown as RuleEmailPOST).action.recipient,
        ) as RecipientWithoutCaller,
      };
    case RuleActionTypes.WEBHOOK:
      return {
        ...(rule.action as RuleWebhookAction),
        type: RuleActionTypes.WEBHOOK,
      };
    case RuleActionTypes.WORKBENCH:
      return {
        ...rule.action,
        type: RuleActionTypes.WORKBENCH,
      };
    case RuleActionTypes.EVENT:
      return {
        ...rule.action,
        type: RuleActionTypes.EVENT,
      };
    case RuleActionTypes.TASK_STATUS_CHANGE:
      const taskChangeAction = (rule as unknown as RuleTaskStatusChangePOST).action;
      return {
        type: RuleActionTypes.TASK_STATUS_CHANGE,
        taskExternalId: taskChangeAction?.taskExternalId || '',
        changeTasksStatusTo: taskChangeAction?.changeTasksStatusTo || TaskStatus.DONE,
      };
    case RuleActionTypes.DEVICE_LOCATION_CHANGE: {
      return {
        ...rule.action,
        type: RuleActionTypes.DEVICE_LOCATION_CHANGE,
      };
    }
    case RuleActionTypes.FUNCTION: {
      return {
        ...(rule.action as IFunctionAction),
        type: RuleActionTypes.FUNCTION,
      };
    }
    case RuleActionTypes.SKILLS: {
      const action = rule.action as RuleSkillsAction;
      return {
        type: RuleActionTypes.SKILLS,
        userId: action.userId || '',
        skillsToAdd: action.skillsToAdd || [],
        skillsToRemove: action.skillsToRemove || [],
      };
    }
    case RuleActionTypes.ROLES: {
      const action = rule.action as RuleRolesAction;
      return {
        type: RuleActionTypes.ROLES,
        userId: action.userId || '',
        rolesToAdd: action.rolesToAdd || [],
        rolesToRemove: action.rolesToRemove || [],
      };
    }
    default:
      return rule.action ? (rule.action as RuleAction) : undefined;
  }
}

const normalizeTaskAnnouncementFormValues = (announcement: TaskAnnouncement | undefined): TaskAnnouncement => {
  if (!announcement) {
    const notificationAnnouncement: NotificationTaskAnnouncement = {
      type: TaskAnnouncementType.NOTIFICATION,
      notificationType: NotificationTypes.ONLY_AVAILABLE,
    };
    return notificationAnnouncement;
  }

  switch (announcement.type) {
    case TaskAnnouncementType.NOTIFICATION: {
      const notificationAnnouncement: NotificationTaskAnnouncement = {
        type: TaskAnnouncementType.NOTIFICATION,
        notificationType: announcement.notificationType || NotificationTypes.ONLY_AVAILABLE,
      };
      return notificationAnnouncement;
    }

    case TaskAnnouncementType.AUTO_ACCEPT: {
      const autoAcceptAnnouncement: AutoAcceptTaskAnnouncement = {
        type: TaskAnnouncementType.AUTO_ACCEPT,
        currentTaskAction: announcement.currentTaskAction,
      };
      return autoAcceptAnnouncement;
    }

    case TaskAnnouncementType.AWARENESS:
    case TaskAnnouncementType.NONE: {
      const taskAnnouncement: NoneTaskAnnouncement | AwarenessTaskAnnouncement = {
        type: announcement.type,
      };
      return taskAnnouncement;
    }
  }
};

export function normalizeRule(rule: RuleGET): Rule {
  const action = generateRuleActionFromRuleGET(rule);
  // See types file, the backend does not send consistent data.
  // For a GET ALL, the object has the name populated.
  // For a GET BY ID, we only have the eventId as a string.
  let normalizedRuleEventId: string;
  if (typeof rule.event === 'string') {
    normalizedRuleEventId = rule.event;
  } else {
    normalizedRuleEventId = rule.event?._id || '';
  }
  return {
    id: rule._id,
    createdAt: rule.meta.createdAt,
    updatedAt: rule.meta.updatedAt,
    triggeredAt: rule.triggeredAt,
    name: rule.name,
    action,
    description: rule.description,
    active: rule.active,
    projectId: rule.project,
    conditions: rule.filter?.length ? rule.filter : [[]],
    noAction: !!action,
    trigger: generateRuleTriggerFromRuleGET(rule.trigger, normalizedRuleEventId),
    links: rule.links,
  };
}

const mapRecipientToRecipientBody = (recipient: Recipient): ActionRecipient => {
  let recipientPOSTData: ActionRecipient;

  switch (recipient.type) {
    case FERecipientTypes.ROLES:
      recipientPOSTData = {
        type: RecipientTypes.Roles,
        roles: recipient.roles,
      } as IActionRolesRecipient;
      break;
    case FERecipientTypes.USER:
      recipientPOSTData = {
        type: RecipientTypes.User,
        user: recipient.assignedUser,
      } as IActionUserRecipient;
      break;
    case FERecipientTypes.VARIABLES:
      recipientPOSTData = {
        type: RecipientTypes.Variable,
        variables: splitVariables(recipient.variables),
      } as IActionVariableRecipient;
      break;
    case FERecipientTypes.CALLER:
      recipientPOSTData = {
        type: RecipientTypes.Selfinstruction,
      } as IActionSelfInstructionRecipient;
      break;
  }

  return recipientPOSTData;
};

const normalizeActionWITask = (task?: IRuleActionTask): RuleActionTask => ({
  headline: task?.headline || '',
  title: task?.title || '',
  description: task?.description || '',
  priority: task?.priority || '3',
  announcement: normalizeTaskAnnouncementFormValues(task?.announcement),
  rejectReasons: task?.rejectReasons?.map((rejectReason) => rejectReason.text || '') || [],
  connectedTo: task?.connectedTo || [],
});

const mapActionTaskToBody = (task: RuleActionTask): IRuleActionTask => ({
  ...task,
  rejectReasons:
    task.rejectReasons?.map((rejectReason) => ({
      text: rejectReason,
    })) || [],
});

const mapLocationSettingToNotifyLocation = (
  globalLocationSettings?: GlobalLocationSettings,
): NotifyLocation | undefined => {
  if (!globalLocationSettings) {
    return;
  }
  if (globalLocationSettings.isVariable) {
    return {
      type: ValueTypes.Variable,
      variable: globalLocationSettings.locationIdVariable,
    };
  }

  return {
    type: ValueTypes.Value,
    location: globalLocationSettings.locationSettings.map((setting) => ({ ...setting, level: setting.levelId })),
  };
};

function normalizeTaskStatusChangeFormValues(
  baseRule: RulePOSTBase,
  values: RuleTaskStatusChangeAction,
): RuleTaskStatusChangePOST {
  return {
    ...baseRule,
    action: {
      type: RuleActionTypes.TASK_STATUS_CHANGE,
      taskExternalId: values.taskExternalId,
      changeTasksStatusTo: values.changeTasksStatusTo,
    },
  };
}

function normalizeMessageFormValues(baseRule: RulePOSTBase, values: RuleMessageAction): RuleMessagePOST {
  return {
    ...baseRule,
    action: {
      type: RuleActionTypes.MESSAGE,
      message: values.message,
      timeout: values.timeout?.seconds ? { seconds: values.timeout.seconds } : null,
      announcementDelay: values.announcementDelay || null,
      recipient: mapRecipientToRecipientBody(values.recipient),
      ...(values.globalLocationSettings && {
        notifyLocation: mapLocationSettingToNotifyLocation(values.globalLocationSettings),
      }),
    },
  };
}

function normalizeEmailFormValues(baseRule: RulePOSTBase, values: RuleEmailAction): RuleEmailPOST {
  return {
    ...baseRule,
    action: {
      type: RuleActionTypes.EMAIL,
      email: values.email,
      recipient: mapRecipientToRecipientBody(values.recipient) as EmailRecipient,
    },
  };
}

function normalizeRuleTaskAction(baseRule: RulePOSTBase, values: RuleTaskAction): RuleTaskPOST {
  return {
    ...baseRule,
    action: {
      type: values.type,
      task: mapActionTaskToBody(values.task),
      taskSettings: values.taskSettings,
      recipient: mapRecipientToRecipientBody(values.recipient),
      skills: values.skills,
      notifyLocation: mapLocationSettingToNotifyLocation(values.globalLocationSettings),
    },
  };
}

function normalizeWorkinstructionFormValues(
  baseRule: RulePOSTBase,
  values: RuleWorkinstructionAction,
): RuleWorkinstructionPOST {
  return {
    ...baseRule,
    action: {
      type: RuleActionTypes.WORKINSTRUCTION,
      workinstructionId: values.workinstructionId,
      taskSettings: values.taskSettings,
      skills: values.skills,
      recipient: mapRecipientToRecipientBody(values.recipient),
      task: mapActionTaskToBody(values.task),
      notifyLocation: mapLocationSettingToNotifyLocation(values.globalLocationSettings),
    },
  };
}

const normalizeFormAssignmentFormValues = (baseRule: RulePOSTBase, values: RuleFormAction): RuleFormPOST => ({
  ...baseRule,
  action: {
    type: RuleActionTypes.FORM,
    formId: values.formId,
    task: values.task,
    recipient: mapRecipientToRecipientBody(values.recipient),
  },
});

const normalizeKnowledgeCaptureFormValues = (
  baseRule: RulePOSTBase,
  values: RuleKnowledgeCaptureAction,
): RuleKnowledgeCapturePOST => ({
  ...baseRule,
  action: {
    type: RuleActionTypes.KNOWLEDGE_CAPTURE,
    task: values.task,
    documentType: values.documentType,
    recipient: mapRecipientToRecipientBody(values.recipient),
  },
});

const normalizeWorkbenchFormValues = (baseRule: RulePOSTBase, values: RuleWorkbenchAction): RuleWorkbenchPOST => ({
  ...baseRule,
  action: {
    ...values,
    type: RuleActionTypes.WORKBENCH,
  },
});

const normalizeWebhookActionFormValues = (baseRule: RulePOSTBase, values: RuleWebhookAction): RuleWebhookPOST => ({
  ...baseRule,
  action: {
    ...values,
    type: RuleActionTypes.WEBHOOK,
  },
});
const normalizeEventActionFormValues = (baseRule: RulePOSTBase, values: RuleEventAction): RuleEventPOST => ({
  ...baseRule,
  action: {
    ...values,
    type: RuleActionTypes.EVENT,
  },
});

function normalizeDatabaseFormValues(baseRule: RulePOSTBase, values: RuleDatabaseAction): RuleDatabasePOST {
  return {
    ...baseRule,
    action: {
      ...values,
      type: RuleActionTypes.DATABASE,
    },
  };
}

function normalizeFunctionFormValues(baseRule: RulePOSTBase, values: RuleFunctionAction): RuleFunctionPOST {
  return {
    ...baseRule,
    action: {
      ...values,
    },
  };
}

interface RuleWizardToRulePOST {
  projectId: string;
  name: string;
  description: string;
  trigger: Trigger;
  conditions: SelectedCondition[][];
  assignmentValues:
    | RuleTaskStatusChangeAction
    | RuleDeviceLocationChangeAction
    | RuleMessageAction
    | RuleEmailAction
    | RuleTaskAction
    | RuleWorkinstructionAction
    | RuleEventAction
    | RuleDatabaseAction
    | RuleWebhookAction
    | RuleWorkbenchAction
    | RuleFormAction
    | RuleKnowledgeCaptureAction
    | RuleFunctionAction
    | RuleSkillsAction
    | RuleRolesAction
    | RuleMqttPublishAction
    | RuleMqttSubscribeAction
    | RuleUpdateTaskVariablesAction;
  active: boolean;
}
// eslint-disable-next-line consistent-return -- to be refactored as default case must be added to switch
export function convertRuleWizardToRulePOST({
  projectId,
  name,
  description,
  trigger,
  conditions,
  assignmentValues,
  active,
}: RuleWizardToRulePOST): RulePOST {
  const baseRule: RulePOSTBase = {
    name,
    description,
    filter: conditions,
    trigger: {
      type: trigger.type,
      source: trigger.source,
    },
    project: projectId,
    noAction: false, // TODO: make this dynamic
    active,
  };

  if (trigger.type === TriggerTypes.EXTERNAL_EVENT) {
    baseRule.event = trigger.resourceId;
    baseRule.trigger.resourceId = trigger.resourceId;
  } else if (trigger.schedule) {
    baseRule.trigger = {
      ...baseRule.trigger,
      schedule: trigger.schedule,
    };
  }

  switch (assignmentValues.type) {
    case RuleActionTypes.TASK_STATUS_CHANGE:
      return normalizeTaskStatusChangeFormValues(baseRule, assignmentValues as RuleTaskStatusChangeAction);
    case RuleActionTypes.MESSAGE:
      return normalizeMessageFormValues(baseRule, assignmentValues as RuleMessageAction);
    case RuleActionTypes.EMAIL:
      return normalizeEmailFormValues(baseRule, assignmentValues as RuleEmailAction);
    case RuleActionTypes.TASK:
    case RuleActionTypes.DELEGATE:
      return normalizeRuleTaskAction(baseRule, assignmentValues as RuleTaskAction);
    case RuleActionTypes.WORKINSTRUCTION:
      return normalizeWorkinstructionFormValues(baseRule, assignmentValues as RuleWorkinstructionAction);
    case RuleActionTypes.WORKBENCH:
      return normalizeWorkbenchFormValues(baseRule, assignmentValues as RuleWorkbenchAction);
    case RuleActionTypes.WEBHOOK:
      return normalizeWebhookActionFormValues(baseRule, assignmentValues as RuleWebhookAction);
    case RuleActionTypes.EVENT:
      return normalizeEventActionFormValues(baseRule, assignmentValues as RuleEventAction);
    case RuleActionTypes.DATABASE:
      return normalizeDatabaseFormValues(baseRule, assignmentValues as RuleDatabaseAction);
    case RuleActionTypes.FORM:
      return normalizeFormAssignmentFormValues(baseRule, assignmentValues as RuleFormAction);
    case RuleActionTypes.KNOWLEDGE_CAPTURE:
      return normalizeKnowledgeCaptureFormValues(baseRule, assignmentValues as RuleKnowledgeCaptureAction);
    case RuleActionTypes.FUNCTION:
      return normalizeFunctionFormValues(baseRule, assignmentValues as RuleFunctionAction);
    case RuleActionTypes.DEVICE_LOCATION_CHANGE:
      return {
        ...baseRule,
        action: assignmentValues as RuleDeviceLocationChangeAction,
      };
    case RuleActionTypes.ROLES:
      return {
        ...baseRule,
        action: assignmentValues as RuleRolesAction,
      };
    case RuleActionTypes.SKILLS:
      return {
        ...baseRule,
        action: assignmentValues as RuleSkillsAction,
      };
    case RuleActionTypes.MQTT_PUBLISH:
      return {
        ...baseRule,
        action: assignmentValues as RuleMqttPublishAction,
      };
    case RuleActionTypes.MQTT_SUBSCRIBE:
      return {
        ...baseRule,
        action: assignmentValues as RuleMqttSubscribeAction,
      };
    case RuleActionTypes.UPDATE_TASK_VARIABLES:
      return {
        ...baseRule,
        action: assignmentValues as RuleUpdateTaskVariablesAction,
      };
    default: {
      throw new Error('Action is missing "type" property');
    }
  }
}
