import { BuilderSection, BuilderStep, IsSectionStep, isBuilderSection } from '@workerbase/domain/document';
import { generateUniqueSlug } from '../slugify';

const SECTION_PREFIX = 'section-';
const INPUT_PREFIX = 'input-';

export const slugifyBuilderSteps = (
  steps: (BuilderStep | BuilderSection)[],
  forceSlugifyIds: string[] = [],
): (BuilderStep | BuilderSection)[] => {
  const slugMap: string[] = steps
    .filter((step) => step.isCustomVariableName && !forceSlugifyIds.includes(step._id))
    .map((step) => step.variableName);

  return steps.map((step) => {
    const prefix = isBuilderSection(step) ? SECTION_PREFIX : INPUT_PREFIX;

    const slug =
      step.isCustomVariableName && !forceSlugifyIds.includes(step._id)
        ? step.variableName
        : generateUniqueSlug({
            name: step.title || step.type,
            existingSlugs: slugMap,
            prefix,
          });

    if (IsSectionStep(step)) {
      const section = step;
      const newSection = {
        ...section,
        variableName: slug,
        steps: slugifyBuilderSteps(section.steps, forceSlugifyIds) as BuilderStep[],
      };

      slugMap.push(slug);
      return newSection;
    }

    slugMap.push(slug);
    return { ...step, variableName: slug };
  });
};
