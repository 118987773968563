import { getListConfigSaga, updateListConfigSaga } from '@redux/common/ListConfig/sagas';
import { ShiftPlanResponse } from '@workerbase/api/http/settings';
import { SortOrder } from '@workerbase/domain/common';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  createShiftPlan,
  deleteShiftPlanById,
  getShiftPlans,
  updateShiftPlanById,
} from 'services/networking/shiftPlans';
import { PaginationMeta } from '@workerbase/types/Response';
import { Constants } from 'utils/Constants';
import { GlobalSettingsTabs } from '../../pages/Settings/SettingsSideBar/SettingsSideBar';
import { getIntl } from '../../providers/IntlProvider';
import { handleRequestError } from '../common';
import {
  CreateShiftPlanRequestAction,
  deleteShiftPlanByIdError,
  deleteShiftPlanByIdSuccess,
  getShiftPlansError,
  getShiftPlansRequestAction,
  getShiftPlansSuccess,
  ShiftPlansActions,
  UpdateShiftPlanByIdRequestAction,
  getAllShiftPlansSuccessAction,
  getAllShiftPlansErrorAction,
} from './actions';
import { getListConfigs } from './selectors';

export function* getAllShiftPlansRequestSaga(action) {
  try {
    const response = yield call(getShiftPlans, {
      page: 1,
      perPage: Constants.MAX_PER_PAGE,
      sorting: {
        selector: 'name',
        sortDirection: SortOrder.ASC,
      },
    });
    const shiftPlans: ShiftPlanResponse[] = response.data;
    yield put(getAllShiftPlansSuccessAction(shiftPlans));
  } catch (error) {
    yield put(getAllShiftPlansErrorAction((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* getShiftPlansRequestSaga(action) {
  try {
    const listConfigs = yield select(getListConfigs);

    const response = yield call(getShiftPlans, {
      page: listConfigs.pagination.currentPage,
      perPage: listConfigs.pagination.itemsPerPage,
      sorting: {
        selector: 'name',
        sortDirection: SortOrder.ASC,
      },
    });

    const shiftPlans: ShiftPlanResponse[] = response?.data;
    const meta: PaginationMeta = response?.meta;

    const errors = response?.errors;

    if (errors) {
      const errorMessages = errors.map((err) => err.message);
      yield put(getShiftPlansError(errorMessages));
      yield put(handleRequestError({ message: errorMessages }));
    }
    yield put(getShiftPlansSuccess(shiftPlans, meta));
  } catch (error) {
    yield put(getShiftPlansError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* deleteShiftPlanByIdRequestSaga(action) {
  try {
    yield call(deleteShiftPlanById, action.payload.shiftPlanId);

    yield call(toast.success, getIntl().formatMessage({ id: 'settings.shift-plans.messages.deleted' }));
    yield put(deleteShiftPlanByIdSuccess(action.payload.shiftPlanId));
    yield put(getShiftPlansRequestAction());
  } catch (error) {
    yield put(deleteShiftPlanByIdError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* createShiftPlanRequestSaga(action: CreateShiftPlanRequestAction) {
  try {
    yield call(createShiftPlan, action.payload.shiftPlan);
    yield call(toast.success, getIntl().formatMessage({ id: 'settings.shift-plans.messages.created' }));
    yield put(getShiftPlansRequestAction());
    yield put(push(`/settings/${GlobalSettingsTabs.SHIFT_PLANS}`));
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* updateShiftPlanByIdRequestSaga(action: UpdateShiftPlanByIdRequestAction) {
  try {
    yield call(updateShiftPlanById, action.payload.shiftPlanId, action.payload.shiftPlan);
    yield call(toast.success, getIntl().formatMessage({ id: 'settings.shift-plans.messages.updated' }));
    yield put(getShiftPlansRequestAction());
    yield put(push(`/settings/${GlobalSettingsTabs.SHIFT_PLANS}`));
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export default function* shiftPlansSagas() {
  yield takeLatest(ShiftPlansActions.GET_ALL_SHIFT_PLANS_REQUEST, getAllShiftPlansRequestSaga);
  yield takeLatest(ShiftPlansActions.GET_SHIFT_PLANS_REQUEST, getShiftPlansRequestSaga);
  yield takeLatest(ShiftPlansActions.DELETE_SHIFT_PLAN_BY_ID_REQUEST, deleteShiftPlanByIdRequestSaga);
  yield takeLatest(ShiftPlansActions.CREATE_SHIFT_PLAN_REQUEST, createShiftPlanRequestSaga);
  yield takeLatest(ShiftPlansActions.UPDATE_SHIFT_PLAN_BY_ID_REQUEST, updateShiftPlanByIdRequestSaga);
  yield takeLatest(ShiftPlansActions.UPDATE_LISTCONFIG_PROPERTIES, updateListConfigSaga);
  yield takeLatest(ShiftPlansActions.GET_LIST_CONFIG_REQUEST, getListConfigSaga);
}
