import { omit } from 'lodash';

import {
  ProjectResponse,
  ProjectResponseWithCreatedByPopulated,
  ProjectLogResponse,
  ProjectUpdateBody,
  TaskCount,
  ProjectCreateMeta,
} from '@workerbase/api/http/project';
import { TriggerResponse } from '@workerbase/api/http/rule';

import { Languages, MetaInterface } from '@workerbase/domain/common';
import { TriggerTypes } from '@workerbase/domain/rule';

import { Project, ProjectWithCreatedByPopulated } from 'services/types/Project';
import { ProjectLog } from 'services/types/ProjectLog';
import { Trigger, TriggerPayloadPOST, TriggerPayloadSchedulePOST } from 'services/types/Rule/Trigger';
import { FormValues as ProjectFormValues } from 'components/ProjectForm';

const normalizeCommonProjectResponseFields = <
  T extends ProjectResponse | ProjectResponseWithCreatedByPopulated,
  M = T extends ProjectResponse ? MetaInterface : ProjectCreateMeta,
>(
  project: Omit<T, 'taskCount'> & {
    taskCount?: TaskCount;
  },
): Omit<Project, 'meta'> & { meta?: M } => ({
  id: project._id,
  adminRoles: project.adminRoles?.map((roleOrId) => (typeof roleOrId === 'string' ? roleOrId : roleOrId?._id)) ?? [],
  archiveTasks: project.archiveTasks ?? [],
  roles: project.roles?.map((roleOrId) => (typeof roleOrId === 'string' ? roleOrId : roleOrId?._id)) ?? [],
  rolesRaw: project.roles,
  adminRolesRaw: project.adminRoles,
  config: project.config ?? { taskFilter: [], taskDetailsFilter: [] },
  name: project.name ?? '',
  description: project.description ?? '',
  createdAt: project.meta?.createdAt ?? new Date(),
  taskCount: project.taskCount ?? {},
  language: project.language ?? Languages.EN,
  meta: project.meta as M,
});

export const normalizeProjectFormValuesToUpdateBody = (formValues: ProjectFormValues): ProjectUpdateBody => ({
  name: formValues.name,
  description: formValues.description,
  roles: formValues.rolesIds,
  adminRoles: formValues.adminRolesIds,
  archiveTasks: formValues.archiveTasks,
  language: formValues.language,
});

export const normalizeProjectToUpdateBody = (project: Project): ProjectUpdateBody => ({
  name: project.name,
  description: project.description,
  roles: project.roles,
  adminRoles: project.adminRoles,
  archiveTasks: project.archiveTasks,
  language: project.language,
});

export const normalizeProjectResponse = <T extends ProjectResponse | ProjectResponseWithCreatedByPopulated>(
  project: T,
): T extends ProjectResponse ? Project : ProjectWithCreatedByPopulated => normalizeCommonProjectResponseFields(project);

export const normalizeTrigger = (trigger: TriggerResponse): Trigger => {
  const normalizedTrigger: Trigger = {
    ...trigger,
    disabled: !!trigger.disabled,
  };

  if (normalizedTrigger.type === TriggerTypes.INTERNAL_EVENT) {
    return omit(normalizedTrigger, 'resourceId'); // as TriggerInternalEvent
  }

  return normalizedTrigger; // as TriggerExternalEvent
};

export const normalizeProjectLog = ({ _id, level, action, meta, payload }: ProjectLogResponse): ProjectLog => ({
  id: _id,
  level,
  action,
  meta: { createdAt: meta.createdAt || new Date() }, // Question: Can meta.createdAt ever be null or undefined?
  payload: payload || {}, // Question: Can payload ever be null or undefined? If yes then we need to update payload type
});

export const isExternalEventTrigger = (trigger: Trigger): trigger is Trigger & { type: TriggerTypes.EXTERNAL_EVENT } =>
  trigger.type === TriggerTypes.EXTERNAL_EVENT;

export const normalizeTriggerPayload = (trigger: Trigger): TriggerPayloadPOST | TriggerPayloadSchedulePOST => {
  const basePayload: TriggerPayloadPOST = {
    source: trigger.source,
    type: trigger.type,
  };

  if (isExternalEventTrigger(trigger)) {
    return { ...basePayload, resourceId: trigger.resourceId } as TriggerPayloadPOST;
  }

  if (trigger.schedule) {
    return { ...basePayload, schedule: trigger.schedule } as TriggerPayloadSchedulePOST;
  }

  return basePayload;
};
