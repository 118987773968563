import { IPaginatedQueryParams, ApiResponsePaginated } from '@workerbase/api/http/common';
import { ListConfigSorting } from '@workerbase/api/http/user';
import { ShiftPlanBody, ShiftPlanResponse, ShiftPlanUpdate } from '@workerbase/api/http/settings';
import { api } from './api';

const SHIFT_PLANS_ENDPOINT = '/api/v1/shiftplans';

type GetShiftPlans = (options: {
  page: number;
  perPage: number;
  sorting?: ListConfigSorting;
}) => Promise<ApiResponsePaginated<ShiftPlanResponse[]>>;

export const getShiftPlans: GetShiftPlans = async ({ page, perPage, sorting }) => {
  const params: IPaginatedQueryParams = {
    page,
    perpage: perPage,
    sort: sorting?.selector,
    order: sorting?.sortDirection,
  };

  const { data } = await api.get<ApiResponsePaginated<ShiftPlanResponse[]>>(SHIFT_PLANS_ENDPOINT, { params });

  return data;
};

export const deleteShiftPlanById = async (shiftPlanId: string): Promise<boolean> => {
  await api.delete<{ success: boolean }>(`${SHIFT_PLANS_ENDPOINT}/${shiftPlanId}`);

  return true;
};

export const createShiftPlan = async (shiftPlan: ShiftPlanBody): Promise<ShiftPlanResponse> => {
  const {
    data: { data },
  } = await api.post<{ data: ShiftPlanResponse }>(SHIFT_PLANS_ENDPOINT, shiftPlan);

  return data;
};

export const updateShiftPlanById = async (
  shiftPlanId: string,
  shiftPlan: ShiftPlanUpdate,
): Promise<ShiftPlanResponse> => {
  const {
    data: { data },
  } = await api.put<{ data: ShiftPlanResponse }>(`${SHIFT_PLANS_ENDPOINT}/${shiftPlanId}`, shiftPlan);

  return data;
};
