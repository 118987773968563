import { logoutUser, logoutUserDelayed } from '@redux/Login';
import { HttpStatus } from '@workerbase/types/HttpStatus';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getIntl } from 'providers/IntlProvider';
import { getExpirationDateFromJwtToken, getPayloadFromJwtToken } from 'services/auth/jwt.utils';
import { getFreshToken } from 'services/networking/auth';
import { store } from '../..';

const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'token';
const REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'refreshToken';
export const SESSION_TIMEOUT_ERROR_NAME = 'SessionTimeoutError';
/**
 * The threshold of the token lifetime after which the token should be refreshed proactively
 */
const PROACTIVE_REFRESH_THRESHOLD = 0.2; // 20%

export const getAccessTokenFromLocalStorage = () => localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
export const getRefreshTokenFromLocalStorage = () => localStorage.getItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);

export const setAccessTokenInLocalStorage = (token: string) =>
  localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, token);
export const setRefreshTokenInLocalStorage = (token: string) =>
  localStorage.setItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, token);

export const clearAuthTokensFromLocalStorage = () => {
  localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  localStorage.removeItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);
};

export const isRefreshTokenKey = (key: string) => key === REFRESH_TOKEN_LOCAL_STORAGE_KEY;

let isRefreshingToken = false;

/**
 * Retries the original request until it succeeds or reaches the maximum retry count.
 * @returns {Promise<AxiosResponse>} The response of the successful request.
 * @throws {AxiosError} If the request fails with an unauthorized status code.
 */
const retryRequestWithAxios = async (originalRequest: AxiosRequestConfig): Promise<AxiosResponse> => {
  let retryCount = 0;
  const maxRetryCount = 2;

  const retryRequest = async () => {
    try {
      const response = await axios.request(originalRequest);
      return response;
    } catch (error) {
      // If the error is not an axios error, just throw it
      if (!axios.isAxiosError(error)) {
        throw error;
      }

      // If the error is not related to the token status 403 (make it 401 in the future to be semantically correct), just throw it
      if (error.response?.status !== HttpStatus.FORBIDDEN) {
        throw error;
      }

      // If the request has already been retried the maximum number of times, throw the error
      if (retryCount < maxRetryCount) {
        retryCount++;
        console.warn(`Retrying ${originalRequest.url} request... `, retryCount);
        return retryRequest();
      }

      // If the request has been retried the maximum number of times, throw the error
      console.warn(
        `Re-authenticated request failed after maximum of ${maxRetryCount} retries with status 401. Logging out...`,
      );
      throw error;
    }
  };

  return retryRequest();
};

/**
 * Sets a timeout to logout the user when the refresh token expires
 * @param token the jwt refresh token
 */
export const setDelayedLogoutOnSessionExp = (token: string) => {
  const refreshTokenTTL = getExpirationDateFromJwtToken(token);

  const remainingSessionTime = refreshTokenTTL - new Date().getTime();
  if (remainingSessionTime < 0) {
    // Leverage the logoutUserDelayed with 0 delay to show the session expired toast
    store.dispatch(logoutUserDelayed(0));

    return;
  }

  store.dispatch(logoutUserDelayed(remainingSessionTime));
};

const refreshToken = async () => {
  try {
    isRefreshingToken = true;

    const refreshTokenFromStore = getRefreshTokenFromLocalStorage();
    if (!refreshTokenFromStore) {
      return null;
    }

    const tokens = await getFreshToken({ refreshToken: refreshTokenFromStore });
    setAccessTokenInLocalStorage(tokens.loginToken);
    setRefreshTokenInLocalStorage(tokens.refreshToken);

    isRefreshingToken = false;

    setDelayedLogoutOnSessionExp(tokens.refreshToken);

    return tokens;
  } catch (e) {
    isRefreshingToken = false;
    throw e;
  }
};

/**
 * If the refresh token is expired, log the user out and throw an session timeout error
 */
export const checkSessionTTL = () => {
  const refreshJwtToken = getRefreshTokenFromLocalStorage();

  if (refreshJwtToken) {
    const refreshTokenTTL = getExpirationDateFromJwtToken(refreshJwtToken);
    const refreshTokenRemainingTime = refreshTokenTTL - new Date().getTime();
    if (refreshTokenRemainingTime < 0) {
      logoutUserOnSessionTimeout();
    }
  }
};

/**
 * If the auth token is about to expire, refresh it
 */
export const checkProactiveTokenRefresh = async () => {
  try {
    const authJwtToken = getAccessTokenFromLocalStorage();

    if (authJwtToken && !isRefreshingToken) {
      const authTokenDecoded = getPayloadFromJwtToken(authJwtToken);
      const authTokenTTl = authTokenDecoded.exp * 1000;
      const authTokenIssuedAt = authTokenDecoded.iat * 1000;

      const proactiveRefreshThreshold = (authTokenTTl - authTokenIssuedAt) * PROACTIVE_REFRESH_THRESHOLD;
      const authTokenRemainingTime = authTokenTTl - new Date().getTime();

      if (authTokenRemainingTime < proactiveRefreshThreshold) {
        refreshToken();
      }
    }
  } catch (e) {
    console.warn('Error during proactive refreshing of a token', e);
  }
};

/**
 * Refreshes the token and calls the request
 */
export const refreshTokenAndRetryRequest = async (request?: AxiosRequestConfig) => {
  const requestObj: AxiosRequestConfig = { ...request };
  const refreshJwtToken = getRefreshTokenFromLocalStorage();

  if (!refreshJwtToken) {
    store.dispatch(logoutUser());
    throw new Error('Refresh token not found');
  }

  // If the token is already being refreshed, wait for it to be refreshed and retry the request
  if (isRefreshingToken) {
    const refreshPromise = new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        console.warn('Waiting for the token to be refreshed', requestObj?.url);
        if (!isRefreshingToken) {
          clearInterval(interval);
          resolve();
        }
      }, 50);
    });

    await refreshPromise;

    const freshToken = getAccessTokenFromLocalStorage();
    if (!freshToken) {
      // If the token is still not refreshed, reject the request pending request as user is going to be logged out
      return Promise.reject();
    }

    if (requestObj?.headers?.accesstoken) {
      requestObj.headers.accesstoken = freshToken;
    }

    console.warn('Refetching with fresh token', requestObj?.url);
    return axios(requestObj);
  }

  try {
    const tokens = await refreshToken();

    // If the token is not refreshed successfully, reject the request pending request as user is going to be logged out
    if (!tokens) {
      return Promise.reject();
    }

    if (request && request.headers) {
      request.headers.accesstoken = tokens.loginToken;
    }

    const response = await retryRequestWithAxios(requestObj);

    return response;
  } catch (refreshTokenError) {
    logoutUserOnSessionTimeout();
  }
};

/**
 * Logs out the user and throws a session timeout error
 * @throws {Error} The error name is set to {@link SESSION_TIMEOUT_ERROR_NAME}
 * If needed, the error can be caught and handled differently
 * e.g. show a different toast or redirect to a different page
 *
 * @example
 * try {
 *   const response = await axios.get('/api/user');
 * } catch (error) {
 *   if (error.name === SESSION_TIMEOUT_ERROR_NAME) {
 *     // handle the session timeout error
 *   }
 * }
 */
export const logoutUserOnSessionTimeout = () => {
  const intl = getIntl();
  store.dispatch(logoutUser());
  const error = new Error(intl.formatMessage({ id: 'login.session-timeout' }));
  error.name = SESSION_TIMEOUT_ERROR_NAME;
  throw error;
};
