import { TaskCategoryKeys } from '@workerbase/types/TaskCategoryKeys';
import { TaskBody } from '@workerbase/api/http/task';

import { Task, TaskMQTT } from 'services/types/Task';
import { PaginationMeta } from '@workerbase/types/Response';

import { IFilter } from '@workerbase/domain/common';
import { ConditionOperator, AvailableCondition } from '@workerbase/domain/rule';
import { BaseAction } from '../common/actions';
import {
  updateFilteringFactory,
  updateSortingFactory,
  updatePaginationFactory,
  updateSelectedTabKeyFactory,
} from '../common/ListConfig/actions';

export enum TasksActions {
  GET_TASKS_REQUEST = '@@tasks/GET_TASKS_REQUEST',
  GET_TASKS_SUCCESS = '@@tasks/GET_TASKS_SUCCESS',
  GET_TASKS_FAILURE = '@@tasks/GET_TASKS_FAILURE',
  DELETE_TASK_BY_ID_REQUEST = '@@tasks/DELETE_TASK_BY_ID_REQUEST',

  DELETE_TASK_BY_ID_SUCCESS = '@@tasks/DELETE_TASK_BY_ID_SUCCESS',
  DELETE_TASK_BY_ID_FAILURE = '@@tasks/DELETE_TASK_BY_ID_FAILURE',
  GET_TASK_BY_ID_SUCCESS = '@@tasks/GET_TASK_BY_ID_SUCCESS',
  ASSIGN_TASK_TO_USER_ID = '@@tasks/ASSIGN_TASK_TO_USER_ID',
  CREATE_TASK_REQUEST = '@@tasks/CREATE_TASK_REQUEST',
  UPDATE_PAGINATION = '@@tasks/UPDATE_PAGINATION',
  UPDATE_SORTING = '@@tasks/UPDATE_SORTING',
  UPDATE_FILTERING = '@@tasks/UPDATE_FILTERING',
  UPDATE_SELECTED_TAB_KEY = '@@tasks/UPDATE_SELECTED_TAB_KEY',
  UPDATE_CATEGORY_KEY = '@@tasks/UPDATE_CATEGORY_KEY',
  UPDATE_LIST_CONFIG = '@@tasks/UPDATE_LIST_CONFIG',
  MQTT_SUBSCRIBE = '@@tasks/MQTT_SUBSCRIBE',
  MQTT_UNSUBSCRIBE = '@@tasks/MQTT_UNSUBSCRIBE',
  HANDLE_INCOMING_MQTT_TASK = '@@tasks/HANDLE_INCOMING_MQTT_TASK',
}

export interface GetTasksRequestAction {
  type: string;
  payload: {
    projectId: string;
    forceLoading?: boolean;
    isArchiveMode?: boolean;
    filter?: IFilter<ConditionOperator>;
    conditions?: AvailableCondition[];
  };
}
export const getTasksRequestAction = ({
  projectId,
  forceLoading = false,
  isArchiveMode = false,
  filter,
  conditions,
}: GetTasksRequestAction['payload']): GetTasksRequestAction => ({
  type: TasksActions.GET_TASKS_REQUEST,
  payload: { projectId, forceLoading, isArchiveMode, filter, conditions },
});

export const getTasksSuccess = (data: (Partial<Task> & { _id })[] | undefined, meta: PaginationMeta): BaseAction => ({
  type: TasksActions.GET_TASKS_SUCCESS,
  payload: {
    tasks: data,
    meta,
  },
});

export const getTasksError = (errorMessage: string): BaseAction => ({
  type: TasksActions.GET_TASKS_FAILURE,
  payload: {
    errorMessage,
  },
});

export const getTaskByIdSuccess = (data: Task): BaseAction => ({
  type: TasksActions.GET_TASK_BY_ID_SUCCESS,
  payload: {
    task: data,
  },
});

// DELETE_TASK_BY_ID
export interface DeleteTaskByIdRequestAction {
  type: string;
  payload: {
    taskId: string;
  };
}
export const deleteTaskByIdRequest = (taskId: string): DeleteTaskByIdRequestAction => ({
  type: TasksActions.DELETE_TASK_BY_ID_REQUEST,
  payload: { taskId },
});

export const deleteTaskByIdSuccess = (taskId: string): DeleteTaskByIdRequestAction => ({
  type: TasksActions.DELETE_TASK_BY_ID_SUCCESS,
  payload: {
    taskId,
  },
});

export const deleteTaskByIdError = (errorMessage: string): BaseAction => ({
  type: TasksActions.DELETE_TASK_BY_ID_FAILURE,
  payload: {
    errorMessage,
  },
});

export interface AssignTaskToUserIdRequestAction {
  type: string;
  payload: {
    taskId: string;
    userId: string;
  };
}

export const assignTaskToUserIdRequest = (taskId: string, userId: string): AssignTaskToUserIdRequestAction => ({
  type: TasksActions.ASSIGN_TASK_TO_USER_ID,
  payload: {
    taskId,
    userId,
  },
});

export interface CreateTaskRequestAction {
  type: string;
  payload: {
    workinstructionId: string;
    task: TaskBody;
  };
}
export const createTaskRequest = (workinstructionId: string, task: TaskBody): CreateTaskRequestAction => ({
  type: TasksActions.CREATE_TASK_REQUEST,
  payload: { workinstructionId, task },
});

export const updatePagination = updatePaginationFactory(TasksActions.UPDATE_PAGINATION);
export const updateSorting = updateSortingFactory(TasksActions.UPDATE_SORTING);
export const updateFiltering = updateFilteringFactory(TasksActions.UPDATE_FILTERING);
export const updateSelectedTabKey = updateSelectedTabKeyFactory(TasksActions.UPDATE_SELECTED_TAB_KEY);

export const subscribeToMqttTasks = (projectToSubscribe: string) => ({
  type: TasksActions.MQTT_SUBSCRIBE,
  payload: { projectSubscribed: projectToSubscribe },
});

export const unsubscribeToMqttTasks = () => ({
  type: TasksActions.MQTT_UNSUBSCRIBE,
});

export interface HandleIncomingMqttTaskAction {
  type: string;
  payload: {
    topic: string;
    message: { data: TaskMQTT };
    regexpArray: string[];
  };
}
export const handleIncomingMqttTask = (topic, message, regexpArray): HandleIncomingMqttTaskAction => ({
  type: TasksActions.HANDLE_INCOMING_MQTT_TASK,
  payload: {
    topic,
    message,
    regexpArray,
  },
});

export const updateCategoryKey = (category: TaskCategoryKeys) => ({
  type: TasksActions.UPDATE_CATEGORY_KEY,
  payload: {
    category,
  },
});
