import { ListConfigSorting } from '@workerbase/api/http/user';
import { TaskBody } from '@workerbase/api/http/task';
import { TaskCategoryKeys } from '@workerbase/types/TaskCategoryKeys';

import { TaskGET, TaskField, TaskHistory, Task } from 'services/types/Task';

// I don't like to use another resource endpoint here
// but somehow, a task POST is performed against the WORKINSTRUCTIONS endpoint.
// I keep this createTask function here but we may need to make it more relevant later.
import { WORKINSTRUCTIONS_ENDPOINT } from 'services/networking/workinstructions';
import { PROJECTS_ENDPOINT } from 'services/networking/projects';
import { GraphQLListResponse, ListArgs, GroupOperation, GraphQLGroupResponse } from 'services/types/GraphQL';
import { GraphQLOperators } from '@workerbase/types/graphql/GraphQLOperators';
import { TaskStatus } from '@workerbase/domain/task';
import { makeGraphqlListRequest, makeGraphqlGroupRequest } from './graphql';

import { api } from './api';
import { normalizeTask, normalizeTaskFields } from '../normalizers/tasks';

const TASKS_ENDPOINT = '/api/v1/tasks';
const TASKS_GRAPHQL_MODEL = 'tasks';

// TODO: set return type
export const getTasks = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterQuery: Record<string, any>,
  page = 1,
  perPage = 20,
  sorting?: ListConfigSorting,
  filtering?: string,
  selectedTabKey?: string | boolean,
  categoryKey?: string,
  additionalPropertiesPaths?: string[],
  preciseCount?: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const baseFields = [
    '_id',
    'user._id',
    'user.firstName',
    'user.lastName',
    'status',
    'delegationLevel',
    'meta.createdAt',
    'meta.updatedAt',
    'payload.workinstructions.name',
  ];
  const fields = !additionalPropertiesPaths ? baseFields : [...baseFields, ...additionalPropertiesPaths];

  const fieldsWithNoSubfieldQuerying = ['payload.workinstructions.steps', 'payload.data', 'payload.variables'];

  const fieldsToRequest = fields
    .map(
      (field) =>
        fieldsWithNoSubfieldQuerying.find((fieldWithNoSubfields) => field.startsWith(fieldWithNoSubfields)) || field,
    )
    .filter((val, i, arr) => i === arr.indexOf(val));

  const categoryFilter =
    categoryKey &&
    categoryKey
      .split('.')
      .reverse()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .reduce((nestedFilter: Record<string, any>, key) => ({ [key]: nestedFilter }), {
        [GraphQLOperators.EQ]: selectedTabKey,
      });

  const gqlArgs: ListArgs = {
    page,
    perpage: perPage,
    sort: sorting?.selector,
    order: sorting?.sortDirection,
    filter: {
      ...filterQuery,
      ...categoryFilter,
      deleted: {
        EQ: false,
      },
    },
    textSearch: filtering,
  };

  const {
    data: { data, errors },
  } = await makeGraphqlListRequest<{
    tasks?: GraphQLListResponse<Partial<TaskGET> & { _id: string }>;
  }>(TASKS_GRAPHQL_MODEL, fieldsToRequest, gqlArgs, preciseCount ?? false);

  const tasks = data.tasks?.edges;
  const meta = data.tasks?.pageInfo;

  return {
    tasks: tasks?.map((task) => normalizeTask(task, additionalPropertiesPaths)),
    meta: {
      ...meta,
      categoryKey,
      category: selectedTabKey,
    },
    errors,
  };
};

// TODO: set return type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTasksStats = async (filterQuery: Record<string, any>, categoryKey: string): Promise<any> => {
  const args: ListArgs = {
    group: { key: categoryKey, operation: GroupOperation.COUNT },
    filter: filterQuery,
  };
  if (!categoryKey || categoryKey === TaskCategoryKeys.NO_KEY) {
    return { stats: [] };
  }

  const {
    data: { data, errors },
  } = await makeGraphqlGroupRequest<{
    tasks?: GraphQLGroupResponse;
  }>(TASKS_GRAPHQL_MODEL, args);

  return {
    stats: data.tasks?.groupedEdges?.map(({ _id, value }) => ({
      [categoryKey]: _id,
      count: value,
    })),
    errors,
  };
};

export const deleteTaskById = async (taskId: string): Promise<boolean> => {
  await api.delete<unknown>(`${TASKS_ENDPOINT}/${taskId}`);

  return true;
};

export const assignTaskToUserId = async (taskId: string, userId: string): Promise<boolean> => {
  await api.post<unknown>(`${TASKS_ENDPOINT}/${taskId}/assign`, { userId });

  return true;
};

// TODO: should be 201 CREATED here. Need to update status code from backend.
export const createTask = async (workinstructionId: string, task: TaskBody): Promise<Task> => {
  // Here the workinstruction id is used both in the URL and in the payload
  // It can be confusing.
  const {
    data: { data },
  } = await api.post<{ data: TaskGET }>(`${WORKINSTRUCTIONS_ENDPOINT}/${workinstructionId}/tasks`, task);

  return normalizeTask(data);
};

// TODO: set return type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTasksFieldsForProject = async (projectId: string): Promise<any> => {
  const {
    data: { data },
  } = await api.get<{ data: TaskField[] }>(`${PROJECTS_ENDPOINT}/${projectId}/taskFields`);

  return normalizeTaskFields(data);
};

export const getHistoryByTaskId = async (taskId: string): Promise<TaskHistory[]> => {
  const {
    data: { data },
  } = await api.get<{ data: TaskHistory[] }>(`${TASKS_ENDPOINT}/${taskId}/history`);

  return data;
};

export const archiveTasksForProject = async (projectId: string, statusFilters: TaskStatus[]): Promise<boolean> => {
  await api.put<unknown>(`${TASKS_ENDPOINT}/archive`, {
    project: projectId,
    statusFilters,
  });

  return true;
};
